@use "base-styles/abstracts/breakpoints" as bp;
@use "base-styles/abstracts/typography" as t;
@use 'base-styles/abstracts/spacing' as s;
@use 'base-styles/abstracts/icons' as i;

p-calendar.ng-invalid.ng-touched {
  .p-inputtext {
    outline: 2px solid var(--error-600) !important;
    border-radius: 0 !important;
    border: none !important;
  }

  .p-datepicker {
    outline: 2px solid var(--error-600) !important;
  }
}

.p-calendar {
  width: 202px;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;

  &:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0;
    outline: 2px solid var(--tone-700);
  }

  &:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
  }
}

.p-calendar-w-btn {
  cursor: pointer;

  .p-inputtext {
    border: none !important;
    outline: 1px solid var(--tone-700) !important;
    transform: none;
    cursor: pointer;
    transition: none;
  }
}

.p-calendar--open .p-inputtext {
  outline: 2px solid var(--tone-700) !important;
  border: none !important;
}

.p-datepicker .p-datepicker-header {
  padding: 10px 5px;
  color: var(--tone-700);
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid var(--tone-400);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-calendar-w-btn .p-datepicker-trigger {
  display: flex;
  align-items: center;
  font-size: 18px;
  position: absolute;
  border: 0;
  background: transparent;
  right: 0;
  height: 100%;

  .icon {
    font-size: 23px;
    height: 23px;
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  border: 0 none;
  background: transparent;
  border-radius: 50%;

  &:hover {
    background-color: #ebebeb;
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: var(--primary-700);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}

.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.p-datepicker table th {
  padding: 0.5rem;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0.5rem;
}

.pi-chevron-right,
.pi-chevron-left {
  font-size: 11px;
  margin-top: 4px;

  &:before {
    @include i.base();
    color: var(--tone-700);
  }
}

.pi-chevron-right {
  &:before {
    @include i.icon(arrow-right);
  }
}

.pi-chevron-left {
  &:before {
    @include i.icon(arrow-left);
  }
}

.p-datepicker table td > span {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1px solid transparent;
  font-size: 12px;
  font-family: var(--typog-default-body-family);
  font-weight: 600;
  transition: all ease 0.3s;
  color: var(--tone-700);

  &:not(.p-disabled):hover {
    background: var(--primary-700);
    color: #fff;
  }

  &.p-disabled {
    font-weight: 300;
    color: var(--tone-600);
  }
}

.p-datepicker table thead th > span {
  font-size: 13px;
  font-weight: 600;
  color: var(--tone-700);
}

.p-datepicker table thead th.p-disabled > span {
  font-weight: 300;
  color: var(--tone-600);
}

.p-datepicker table td > span.p-highlight {
  color: #fff;
  background: var(--primary-700);
}

.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-datepicker table td.p-datepicker-today > span {
  background: var(--tone-400);
  color: var(--tone-600);
  border-color: transparent;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #fff;
  background: var(--primary-700);
}
