.cdk-global-overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 1000;
  background: rgb(51 51 51 / 50%);
}

ui-button .icon {
  width: unset;
  height: unset;
}

.alert-dialog {
  background-color: #fff;
}
