// TODO WL: Fixed pixels could be converted into the shared styles with spacing mixins. Needs going through each usage to resolve.
@use "base-styles/abstracts/breakpoints" as bp;
// spacer
.row--spacer {
  margin-top: 30px;
  margin-bottom: 30px;

  @include bp.breakpoint(lg) {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
// spacer small
.row--spacer-small {
  margin-bottom: 10px;
  margin-top: 10px;
  @include bp.breakpoint(lg) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
// top
.row--spacer-top {
  margin-top: 30px;

  @include bp.breakpoint(lg) {
    margin-top: 45px;
  }
}

// top - small
.row--spacer-top-small {
  margin-top: 10px;

  @include bp.breakpoint(lg) {
    margin-top: 20px;
  }
}
// top - medium
.row--spacer-top-medium {
  margin-top: 15px;

  @include bp.breakpoint(lg) {
    margin-top: 30px;
  }
}
// top - large
.row--spacer-top-large {
  margin-top: 40px;

  @include bp.breakpoint(lg) {
    margin-top: 60px;
  }
}

// bottom
.row--spacer-bottom {
  margin-bottom: 30px;

  @include bp.breakpoint(lg) {
    margin-bottom: 45px;
  }
}
// bottom - small
.row--spacer-bottom-small {
  margin-bottom: 10px;
}
// bottom - mediun
.row--spacer-bottom-medium {
  margin-bottom: 15px;

  @include bp.breakpoint(lg) {
    margin-bottom: 30px;
  }
}
// bottom - large
.row--spacer-bottom-large {
  margin-bottom: 40px;

  @include bp.breakpoint(lg) {
    margin-bottom: 70px;
  }
}

.row--padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.no-padding {
  padding: 0;
}

.row--padding-large {
  padding-top: 30px;
  padding-bottom: 30px;
}

.row--padding-top-large {
  padding-top: 30px;
}
.row--padding-bottom-large {
  padding-bottom: 30px;
}

.card-spacer {
  display: block;
  padding: 20px;
  text-align: center;
  background: var(--panel-bg--highlight-subtle);

  @include bp.breakpoint(md) {
    padding: 25px 30px 30px 30px;
  }
}

.call-us {
  display: inline-block;
  position: relative;
  margin: 40px 0 20px 0;
  &__number {
    margin: 40px 0 0 0;
  }
}

.icon-round {
  background: var(--panel-bg--highlight-subtle);
  border-radius: 30px;
  height: 24px;
  width: 24px;
  margin: 0px auto;
  -ms-transform: scale(4);
  transform: scale(3);
}

.model-card {
  display: block;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

@include bp.breakpoint(sm) {
  .call-us {
    margin: 20px 0;

    &__number {
      float: left;
      margin: 0;
    }
  }

  .icon-round {
    margin: 20px 40px 0 0;
    float: left;
  }
}
